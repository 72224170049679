import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Home" />
      <div className="w-full lg:w-3/6 my-4">
        <h1 className="text-5xl sm:text-6xl font-bold my-6">Hey! I’m Sid</h1>
        <p className="my-4 text-xl leading-normal text-gray-700">
          I'm a developer based in Mumbai, India. I work on the{" "}
          <span>
            <a
              className="border-b-2 border-gray-200"
              href="https://gatsbyjs.org"
            >
              Gatsby
            </a>
          </span>{" "}
          core team as a Senior Software Engineer.
        </p>
        <p className="my-4 text-xl leading-normal text-gray-700">
          I write about Gatsby, Web Development and Open Source. I'm currently
          working on Incremental Builds in{" "}
          <a className="border-b-2 border-gray-200" href="https://gatsbyjs.com">
            Gatsby Cloud
          </a>
          .
        </p>
        <p className="text-xl leading-normal my-4 text-gray-700">
          I'm{" "}
          <a
            className="border-b-2 border-gray-200"
            href="https://twitter.com/chatsidhartha"
          >
            chatsidhartha
          </a>{" "}
          on Twitter and{" "}
          <a
            className="border-b-2 border-gray-200"
            href="https://github.com/sidharthachatterjee"
          >
            sidharthachatterjee
          </a>{" "}
          on GitHub. Come say hello!
        </p>
      </div>
      {posts.length ? (
        <div className="w-full lg:w-3/6 my-4">
          <h3 className="uppercase text-base font-bold tracking-wide w-full border-b-2 border-gray-200 pb-2 mb-4 text-gray-500">
            Articles
          </h3>
          <ul className="flex flex-col">
            {posts.map(({ node }) => {
              const title = node.frontmatter.title || node.fields.slug
              return (
                <li className="lg:w-5/6 mb-8">
                  <article key={node.fields.slug}>
                    <header className="font-semibold text-2xl mb-1">
                      <Link to={node.fields.slug}>
                        <div className="flex items-baseline">
                          <span>{title}</span>
                          <svg
                            className="h-4 pl-4 text-gray-500 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M16.172 9l-6.071-6.071 1.414-1.414L20 10l-.707.707-7.778 7.778-1.414-1.414L16.172 11H0V9z" />
                          </svg>
                        </div>
                      </Link>
                    </header>
                    <section>
                      <p
                        className="text-xl leading-normal text-gray-700"
                        dangerouslySetInnerHTML={{
                          __html: node.frontmatter.description || node.excerpt,
                        }}
                      />
                    </section>
                  </article>
                </li>
              )
            })}
          </ul>
        </div>
      ) : null}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
